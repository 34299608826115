// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see ttps://fresco-docs.netlify.com/#/carousel/background-video

// Run function on resize
$(document).ready(function () {
  totaliserResize();
  $(window).resize(function() {
    totaliserResize();
  });
});

function totaliserResize() {
  var carouselDetailsWidth = $('.carouselSlideDetail').innerWidth();
  var $totaliser =  $('.carousel .totaliserPanel .totaliserBG');
  var navBreakpointMd = '769';
  if (window.matchMedia('(min-width:' + navBreakpointMd + 'px)').matches) {
    $totaliser.css({ 'height' : 'calc(' + carouselDetailsWidth + 'px - 3rem)' });
  } else {
    $totaliser.css({ 'height' : 'calc(' + carouselDetailsWidth + 'px - 2rem)' });
  }
}


// Card no baner
var $card = $(".listedPost, .feedItem");
var $cardDetail = $(".listedPostText, .feedItemDetailsWrapper");

$card.each( function() {
  if (($(this).find('.banner').length === 0)) {
      $(this).find($cardDetail).css("margin-top", "0");
  }
});


$('.homepage .carouselSlideReadMore').text('Donate');